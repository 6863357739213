<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-lg-6 d-md-flex justify-content-center flex-column py-5">
            <div class="py-5 text-center">
              <h1 class="mt-4 mt-md-0 text-primary">Gratulálunk!</h1>              
              <p class="text-dark font-secondary fw-600 display-3">Érvényes pályázat esetén megnyerheted</p>
              <img v-if="winnerType == 'napi'" src="/imgs/daily-brush.svg" alt="napi nyememény" class="img-fluid" />
              <img v-if="winnerType == 'heti'" src="/imgs/weekly-brush.svg" alt="heti nyeremény" class="img-fluid" />
              <img v-if="winnerType == 'fodij'" src="/imgs/winner-brush.svg" alt="fődíj" class="img-fluid" />           
            </div>
          </div>      
          
          <div class="col-12 col-lg-5 offset-lg-1 align-self-center pb-5">
            <div class="winnerType-bgr text-center py-5">
                <img v-if="winnerType == 'napi'" src="/imgs/nyeremeny-napi-mobile.png" alt="napi nyememény" class="img-fluid" />
                <img v-if="winnerType == 'heti'" src="/imgs/nyeremeny-hetente-mobile.png" alt="heti nyeremény" class="img-fluid" />
                <img v-if="winnerType == 'fodij'" src="/imgs/nyeremeny-fodij-mobile.png" alt="fődíj" class="img-fluid" />
            </div>
          </div>
        </div>   
    </div>    
  </div>
  <Ribbon/>
</div>
</template>

<script>
import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        Ribbon
    },
    props:['winnerType']
}
</script>